import { Injectable } from '@angular/core';
import { ServiceOperation } from '../classes/service-operation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersService extends ServiceOperation {

  constructor() {
    super('purchase-orders');
  }

  providersWithStockNeed(): Observable<any> {
    return this.http.get(this.url + '/providers-with-stock-needs', this.options);
  }

  createOrderHPlus(body): Observable<any> {
    return this.http.post(this.url + '/send-to-hplus', body, this.options)
  }

  listPurchaseProviderByProducts(body): Observable<any> {
    return this.http.post(this.url + '/get-in-common-providers', body, this.options)
  }

  savePurchaseProviderByProducts (body): Observable<any> {
    return this.http.post(this.url + '/save-in-common-provider', body, this.options)
  }
  export(id: number):Observable<any> {
    return this.http.get(this.url + '/export?id=' + id, {...this.options, responseType:'blob'} )
  }
}
