import { Component, Input, Output,  EventEmitter } from '@angular/core';
import { ModalConfirmComponent } from '../../components/modal-confirm/modal-confirm.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-button-upload-files',
  templateUrl: './button-upload-files.component.html',
  styleUrls: ['./button-upload-files.component.scss']
})
export class ButtonUploadFilesComponent {

  @Input() multiple: boolean =  false;
  @Input() mimeTypesAllowed : Array<string>
  @Output() onUpload = new EventEmitter<File[]>()

  constructor(public dialog: MatDialog) { }
/**
 * Only open message if there is any error :)
 *
 * @private
 * @param {string} title
 * @param {string} message
 * @memberof ButtonUploadFilesComponent
 */
private openModal(title: string, message: string ){
    this.dialog.open(ModalConfirmComponent, {
      width: '350px',
      data: { title, message }
    });
  }
/**
 * Emit event with File Array;
 *
 * @param {*} event
 * @returns
 * @memberof ButtonUploadFilesComponent
 */
uploadFile(event) {
    const files = event.target.files;
    if ( this.mimeTypesAllowed) {
      let allow = true;
      for (let file of files) {
        if ( !this.mimeTypesAllowed.includes(file.type)) {
          this.openModal('¡Cuidado!', 'Este formato de archivo no esta permitido');
          allow = false
          break;
        }
      }
      if (!allow) return false;
    }

    this.onUpload.emit(files);
  }
}
