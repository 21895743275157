import { Component, OnInit, Inject, Input, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-modal-simple-input',
  templateUrl: './modal-simple-input.component.html',
  styleUrls: ['./modal-simple-input.component.scss']
})
export class ModalSimpleInputComponent implements OnInit {
  valueString: string;
  title: string;
  place: string;
  type: string;
  constructor(
    public dialogRef: MatDialogRef<ModalSimpleInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.type = this.data.type || 'input'
    this.title = this.data.title || 'Filter'
    this.place = this.data.place || 'Buscar'
    this.valueString = this.data.valueString || ''
  }
  ngOnInit() {}
  sendInfo() {
    this.dialogRef.close(this.valueString)
  }
}