import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {
  title: string = 'Información'
  message: string | Observable<string>
  constructor(
    public dialogRef: MatDialogRef<ModalAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
      if(this.data.title) this.title = this.data.title
      if(this.data.type == 'observable') {
        this.message = ''
        this.data.message.subscribe( (data: any) => {
          console.log(data)
          this.message += data
        });
      }
      else this.message = this.data.message;
    }

    onNoClick(confirm: boolean): void {
      this.dialogRef.close(confirm);
    }
    @HostListener('window:keyup', ['$event'])
    closeModal(event) {
      if(event.keyCode === 27) {
        this.dialogRef.close();
      }
    }

}
