import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-modal-help',
  templateUrl: './modal-help.component.html',
  styleUrls: ['./modal-help.component.scss']
})
export class ModalHelpComponent implements OnInit {
  url: string;
  constructor(
    public dialogRef: MatDialogRef<ModalHelpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {
      if(this.data.file) this.url = `/assets/markdown/${this.data.file}.md`
    }
    onNoClick(): void {
      this.dialogRef.close();
    }

}
