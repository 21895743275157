import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Filter } from '../../interfaces/filter.interface';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { QueryFetcher } from 'src/app/classes/QueryFetcher';

export interface IModalFilter {
  single?: boolean
  filters: Array<{label: string, value: string, operator?: string}>,
  relations? : Array<string>,
  service: any,
  label: string
}

@Component({
  selector: 'app-modal-filter',
  templateUrl: './modal-filter.component.html',
  styleUrls: ['./modal-filter.component.scss']
})
export class ModalFilterComponent implements OnInit {
  loading: boolean = false; // Show loading component
  results: Array<any> = [] // Results of query search
  field: string;
  searchString: string; // String for searching
  searchString$: Subject<string> = new Subject() // Obsevable wait 1000 ms to call search method
  filter: Filter; // Object filter is public because is shown into the view.
  queryFetcher: QueryFetcher // Is public into the component.
  output: boolean = false; // Show or hide ouput element
  singleOptionId : number; // If data.single is true, we need this public var with id of item selected.
  constructor(
    public dialogRef: MatDialogRef<ModalFilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalFilter) { }

  ngOnInit() {
    let parent: any = document.querySelector('app-modal-filter').parentNode
        parent.style.position = 'relative'

    // Set first option with first value filters
    this.field = this.data.filters[0].value

    // subscribe searchString wait 1s and call to search.
    this.searchString$.pipe(
      debounceTime(1000),
      filter(string => !!string),
    ).subscribe((e: string) => this.search())

  }


  /*
      Call event keyup. Search items from custom service <data.service>
      Generate a filter global.
  */
  search() {
    // Set output false hidden output element.
    this.output = false;
    // Get operator from filters where field is equal
    let operator = this.data.filters.find(elem => elem.value == this.field).operator
    // Generate filter
    this.filter = { id: 1, field: this.field, value: operator == 'LIKE' ? `%${this.searchString.trim()}%` : this.searchString.trim() , operator: operator || '=' }

    // Generate queryTable
        this.queryFetcher = new QueryFetcher({ relations: this.data.relations })
        this.queryFetcher.filters.push(this.filter)

    // Api call
    this.loading = true;
    this.data.service.getAll(this.queryFetcher).subscribe(response => {
      this.loading = false;
      this.results = response.data
      this.output = true;
      if(this.data.single) this.singleOptionId = null
    })

  }

  /*
      Close modal.
      @params confirm <boolean> true close and send data , false only close modal.
  */
  onNoClick(confirm: boolean) {
    let send;
    // If confirm is true, send data.
    if(confirm) {
        // If data.single is true and this.singleOptionId
        if(this.data.single && this.singleOptionId ){
          // Modify queryTable filter, with single filter.
          this.filter = { id: 1, field: 'id', value: this.singleOptionId.toString() , operator:  '=' }
          // Override queryTable filters with new filter.
          this.queryFetcher.filters = [this.filter]
          // Set send object
          send = {
            queryTable : this.queryFetcher,
            filter: this.filter,
            data: this.results.find(item => item.id == this.singleOptionId )
          }

        }else {
          // If results length is equal 1 send result, always send global filter.
          send = {
            queryTable: this.queryFetcher,
            filter: this.filter,
            data: this.results.length == 1 ?  this.results[0] : null
          }
        }

    }
    // Close Modal.
    this.dialogRef.close(send);
  }

}
