<div class="header mat-typography" fxLayout="row" fxLayoutAlign="flex-start center">
  <mat-icon>warning</mat-icon>
  <h1 class="title">¿Seguro?</h1>
</div>

<mat-dialog-content>
  <div class="mat-typography">
    Escribe <strong>BORRAR</strong> para confirmar
  </div>
  <p>
      <mat-form-field appearance="outline" color="warn">
        <mat-label>Escribe aquí</mat-label>
        <input (keyup)="checkInput()" matInput [(ngModel)]="delete" #inputDelete>
      </mat-form-field>
  </p>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="flex-end">
  <button mat-button (click)="onNoClick(false)">Cancelar</button>
  <button mat-button  (click)="onNoClick(true)" color="warn" [disabled]="!canDelete" >ACEPTAR</button>
</mat-dialog-actions>


