import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[colorQuantityPurchase]'
})
export class ColorQuantityPurchaseDirective {
  @Input() value: string | number;
  constructor(
    private element: ElementRef
  ) {

  }
  ngOnInit() {
      let num = this.value
      if(num <= 0) this.element.nativeElement.style.color = 'red'
      else this.element.nativeElement.style.color = 'green'
    }

}
