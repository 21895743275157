import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WeatherService } from '../../services/weather.service';
import { Observable} from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-weather',
  templateUrl: './modal-weather.component.html',
  styleUrls: ['./modal-weather.component.scss'],
})
export class ModalWeatherComponent implements OnInit {
  weather$: Observable<any>;
  constructor(
    private weatherS: WeatherService,
    public dialogRef: MatDialogRef<ModalWeatherComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.weather$ = this.weatherS.getWeather()
    this.weatherS.getApiWeather();
  }

}
