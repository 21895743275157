import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'ngx-localstorage'
import { Crypto } from '../classes/Crypto';
import { environment } from 'src/environments/environment';
import { QueryFetcher } from '@/classes/QueryFetcher';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UsersService  {
  crypto = new Crypto()
  options: {headers: HttpHeaders, withCredentials: boolean};
  constructor(
    private _http: HttpClient,
    private _storage: LocalStorageService
  ) {
    this.setHeaders()

  }
  getAll(query?: QueryFetcher) : Observable<any> {
    return this._http.get( `${environment.apiUrl}/users?q=${encodeURIComponent(JSON.stringify(query))}`, this.options )
  }
  me() {
    return this._http.get( `${environment.apiUrl}/user-info`, this.options)
  }

  setHeaders() {
    const localUser = this._storage.get('user')
    if(localUser) {
      const _headers = new HttpHeaders()
      const headers = _headers
        .append('contentType', 'application/json')
        .append('authorization',`Bearer ${this.crypto.descrypt(localUser).token}`)
      this.options = {headers, withCredentials: true}
    }
  }
}
