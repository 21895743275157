<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutAlign="center center">
  <span fxFlex="1%">[{{ filter.id }}]</span>
  <!-- Field -->
  <mat-form-field fxFlex="30%">
    <mat-select name="field" placeholder="Columna" [(ngModel)]="filter.field" (selectionChange)="changeColumn($event)"
      [disabled]="disabled">
      <mat-option *ngFor="let column of columns" [value]="column.value" [style.display]="column.noFilter == true ? 'none' : 'block' "
        [disabled]="column.disabledFilter">
        {{ column.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- Operator -->
  <mat-form-field fxFlex="30%">
    <mat-select  name="operator" placeholder="Operador" [(ngModel)]="filter.operator" (selectionChange)="checkOperator()"
      [disabled]="disabled">
      <ng-container *ngFor="let operator of operators">
        <mat-option class="fira" [value]="operator.value" *ngIf="columnSelected ?  columnSelected.operator ? operator.value == columnSelected.operator : true  : true">
          {{ operator.label }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <!-- ********** Value **************-->

  <ng-container *ngIf="columnSelected">
    <!-- Autocomplete  -->
    <app-chips-autocomplete 
      fxFlex="30%" [column]="columnSelected" 
      *ngIf="columnSelected.search && ( filter.operator == 'NOT IN' || filter.operator == 'IN' || filter.operator == '=' || filter.operator == '!=' ) " 
      [value]="filter.value"
      (onChangeValue)="filter.value = $event; send()" [operator]="filter.operator">
    </app-chips-autocomplete>

    <!-- Date -->
    <mat-form-field fxFlex="30%" *ngIf="columnSelected.type == 'date'">
      <input [disabled]="disabled" matInput [matDatepicker]="picker" placeholder="Elige una fechas" (dateChange)="changeDate($event)"
        (click)="picker.open()" [value]="filter.value">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </ng-container>

  <!-- Text -->
  <mat-form-field (dblclick)="textarea()" fxFlex="30%" *ngIf="!columnSelected || ( !columnSelected.search && columnSelected.type == 'text' && !columnSelected.suggestions ) "
    hintLabel="Puedes pulsar la tecla ENTER para filtar">
    <input  autocomplete="off"  [disabled]="disabled" name="value" matInput placeholder="Valor" [(ngModel)]="filter.value" (blur)="send()"
      [disabled]="filter.value == 'null' || ( !filter.field || !filter.operator)">

  </mat-form-field>


  <mat-form-field (dblclick)="textarea()" fxFlex="30%" *ngIf="(columnSelected && !columnSelected.suggestions ) && columnSelected.search && ( !filter.operator || (filter.operator != 'IN' && filter.operator != 'NOT IN')  && filter.operator != '=' && filter.operator != '!=') "
    hintLabel="Puedes pulsar la tecla ENTER para filtar">
    <input  autocomplete="off" [disabled]="disabled" name="value" matInput placeholder="Valor" [(ngModel)]="filter.value" (blur)="send()"
      [disabled]="filter.value == 'null' || ( !filter.field || !filter.operator)" >
  </mat-form-field>


  <mat-form-field  *ngIf="columnSelected && columnSelected.suggestions"  fxFlex="30%" >
    <mat-select placeholder="Elige una" name="value" [(ngModel)]="filter.value" (selectionChange)="send()">
      <mat-option *ngFor="let suggestion of columnSelected.suggestions" [value]="suggestion.value">{{suggestion.label}}</mat-option>
    </mat-select>
  </mat-form-field>


  <!-- Remove Line filter  -->
  <div fxFlex="5%" fxLayoutAlign="center">
    <button mat-icon-button (click)="removeFilter()" [disabled]="disabled">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>
