// Modules
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MarkdownModule, MarkedOptions } from "ngx-markdown";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { AngularmaterialModule } from "../angularmaterial.module";
import { LayoutModule } from "@angular/cdk/layout";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { NgxPermissionsModule  } from 'ngx-permissions';
import { NgJsonEditorModule } from "ang-jsoneditor";
import {MatButtonToggleModule} from '@angular/material/button-toggle'
// Components
import { MainNavComponent } from "./main-nav/main-nav.component";
import { TopMainMenuComponent } from "./top-main-menu/top-main-menu.component";
import { TopBgComponent } from "./top-bg/top-bg.component";
import { FilterTableComponent } from "./filter-table/filter-table.component";
import { FormFieldComponent } from "./filter-table/form-field/form-field.component";
import { DebugSqlComponent } from "./debug-sql/debug-sql.component";
import { FullScreenLoadingComponent } from "./full-screen-loading/full-screen-loading.component";
import { NoDataComponent } from "./no-data/no-data.component";
import { TableSelectorAllComponent } from "./table-selector-all/table-selector-all.component";
import { ChipsAutocompleteComponent } from "./filter-table/chips-autocomplete/chips-autocomplete.component";
import { SteperBallsComponent } from './steper-balls/steper-balls.component';
import { AlertTableComponent } from './alert-table/alert-table.component';
import { FabAdvancedComponent } from './fab-advanced/fab-advanced.component';
import { ResumeProviderWithStockNeedComponent } from './resume-provider-with-stock-need/resume-provider-with-stock-need.component';
import { ButtonUploadFilesComponent } from './button-upload-files/button-upload-files.component';

const COMPONENTS = [
  MainNavComponent,
  TopMainMenuComponent,
  TopBgComponent,
  FilterTableComponent,
  FormFieldComponent,
  DebugSqlComponent,
  FullScreenLoadingComponent,
  NoDataComponent,
  TableSelectorAllComponent,
  ChipsAutocompleteComponent,
  SteperBallsComponent,
  AlertTableComponent,
  CardDashboardComponent,
  FabAdvancedComponent,
  ResumeProviderWithStockNeedComponent,
  ButtonUploadFilesComponent
];
// Modals
import { ModalHelpComponent } from "./modal-help/modal-help.component";
import { ModalConfirmComponent } from "./modal-confirm/modal-confirm.component";
import { ModalAlertComponent } from "./modal-alert/modal-alert.component";
import { ModalWeatherComponent } from "./modal-weather/modal-weather.component";
import { ModalFilterComponent } from "./modal-filter/modal-filter.component";
import { BtnModalSimpleFilterComponent } from "./btn-modal-simple-filter/btn-modal-simple-filter.component";
import { ModalDeleteAdvanceComponent } from './modal-delete-advance/modal-delete-advance.component';
import { ModalSimpleInputComponent } from './modal-simple-input/modal-simple-input.component';
import {DetailWarehouseComponent} from './detailWarehouse/detail.component';
const MODALS = [
  DetailWarehouseComponent,
  BtnModalSimpleFilterComponent,
  ModalHelpComponent,
  ModalConfirmComponent,
  ModalAlertComponent,
  ModalWeatherComponent,
  ModalFilterComponent,
  ModalDeleteAdvanceComponent,
  ModalSimpleInputComponent
];

import { HoverableDirective } from "../directives/hoverable.directive";
import { ColorQuantityPurchaseDirective } from "../directives/color-quantity-purchase.directive";
import { InputDisabledDirective } from "../directives/input-disabled.directive";
import { AnimationNumberDirective } from "../directives/animation-number.directive";
import { AlwaysPositiveDirective } from '../directives/always-positive.directive';
import { CardDashboardComponent } from './card-dashboard/card-dashboard.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { SaveScrollDirective } from '@/directives/save-scroll.directive';
import { ModalSelectComponent } from './modal-select/modal-select.component';



const DIRECTIVES = [
  HoverableDirective,
  ColorQuantityPurchaseDirective,
  InputDisabledDirective,
  AnimationNumberDirective,
  AlwaysPositiveDirective,
  SaveScrollDirective
]
@NgModule({
  imports: [
    EditorModule,
    NgJsonEditorModule,
    NgxPermissionsModule.forChild(),
    RouterModule,
    HttpClientModule,
    MarkdownModule.forRoot({
      loader: HttpClient
    }),
    FormsModule,
    FlexLayoutModule,
    AngularmaterialModule,
    LayoutModule,
    CommonModule,
    MatButtonToggleModule
  ],
  entryComponents: [...MODALS],
  declarations: [...DIRECTIVES, ...MODALS, ...COMPONENTS, FabAdvancedComponent, ButtonUploadFilesComponent, ModalSelectComponent],
  exports: [ ...DIRECTIVES, ...MODALS, ...COMPONENTS]
})
export class ComponentsModule {}
