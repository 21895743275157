import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-modal-delete-advance',
  templateUrl: './modal-delete-advance.component.html',
  styleUrls: ['./modal-delete-advance.component.scss']
})
export class ModalDeleteAdvanceComponent implements OnInit {
  @ViewChild('inputDelete', {static: true}) inputDelete: ElementRef;
  canDelete : boolean = false;
  delete: string;
  constructor(
    public dialogRef: MatDialogRef<ModalDeleteAdvanceComponent>
  ) { }

  ngOnInit() {
    this.inputDelete.nativeElement.focus()
  }

  onNoClick(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }

  checkInput(){
   this.canDelete = this.delete == 'BORRAR' ? true : false

  }
  @HostListener('keyup', ['$event']) onkeyup(event) {
    if(event.keyCode == 13 ){
      this.checkInput()
      if(this.canDelete) this.onNoClick(true)
    }
  }

}
