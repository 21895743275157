import {trigger, state, style, transition, animate, useAnimation, stagger, query, keyframes} from '@angular/animations';
import { flip, bounceIn, bounceOut, bounceInLeft, bounceInRight, bounceOutRight, bounceInDown, bounceInUp} from 'ng-animate';
export const bounceInLeftAnimation = trigger('bounceInLeft',
  [
  transition(':enter', useAnimation(bounceInLeft))
])
export const bounceInRightAnimation = trigger('bounceInRight',
  [
  transition(':enter', useAnimation(bounceInRight))
])

export const fadeAnimation = trigger('fade', [
  transition(':enter', useAnimation(bounceIn)),
  transition(':leave', useAnimation(bounceOut))
])
export const bounceInAnimation = trigger('bounceIn', [
  transition(':enter', useAnimation(bounceIn))
])
export const flipAnimation = trigger('flip', [
  transition(':enter', useAnimation(flip)),
])

export const bounceInUpAnimation = trigger('bounceInUp', [
  transition(':enter', useAnimation(bounceInUp))
])
export const bounceInAndOutAnimation = trigger('bounceInAndOut', [
  state('0', style({
    display: 'none',
  })),
  state('1', style({
    display: 'block',
  })),
  transition('0 => 1', [useAnimation(bounceIn), style({display: 'block'})]),
  transition('1 => 0', useAnimation(bounceOut, {params: {timing: .3}}))
])

export const enterLeftLeaveRight = trigger('enterLeftLeaveRight', [
  state('0', style({
    display: 'none',
  })),
  state('1', style({
    display: 'block',
  })),
  transition('0 => 1', [useAnimation(bounceInLeft), style({display: 'block'})]),
  transition('1 => 0', useAnimation(bounceOutRight, {params: {timing: .3}}))
])
export const staggerShowOnAnimation = trigger('staggerShowOn', [
  transition('* => *', [
    query(':enter',style({transform:'scale(0)', opacity: 0}),{optional: true}),
    query(':enter', stagger('100ms', [
      animate('400ms ease-out',keyframes([
        style({transform:'scale(0)', opacity: 0, offset: 0}),
        style({transform:'scale(1)', opacity: 1, offset: 1})
      ]))
    ]),{optional: true}),
    query(':leave',style({transform:'scale(1)', opacity: 1}),{optional: true}),
    query(':leave', stagger('100ms', [
      animate('400ms ease-out',keyframes([
        style({transform:'scale(1)', opacity: 1, offset: 0}),
        style({transform:'scale(0)', opacity: 0, offset: 1})
      ]))
    ]),{optional: true})
  ])
])
