import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSaveScroll]'
})
export class SaveScrollDirective {
  constructor(el: ElementRef) {
    let scroll = sessionStorage.getItem('scroll-'+location.pathname);
    setTimeout(() => {
      if(scroll) el.nativeElement.scrollTo(0,parseInt(scroll));
      else el.nativeElement.scrollTo(0,0)
    }, 100)
    el.nativeElement.addEventListener('scroll', e => {
      sessionStorage.setItem('scroll-'+ location.pathname, e.target.scrollTop)
    })
  }

}
