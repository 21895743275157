import { Component, OnInit, TemplateRef, Renderer2 } from '@angular/core';
import { NgxPermissionsService, NgxPermissionsConfigurationService } from 'ngx-permissions';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private permissions: NgxPermissionsService,
    private ngxPermissionsConfigurationService: NgxPermissionsConfigurationService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    const permissions = this.permissions.getPermissions()

    // this.ngxPermissionsConfigurationService.addPermissionStrategy('disable', (templateRef: TemplateRef<any>) => {
    //   templateRef.elementRef.nativeElement.nextSibling.classList.add('disabled')
    // });
    // this.ngxPermissionsConfigurationService.setDefaultOnUnauthorizedStrategy('disable')
  }

}
