import { Injectable } from '@angular/core';
import { Filter } from '../interfaces/filter.interface';
import {ServiceOperation} from '../classes/service-operation';
export interface Filterfactory  {
  id?: number;
  name: string;
  belongs_to: string;
  storage?: string | any;
  user_id?: number;
}
@Injectable({
  providedIn: 'root'
})
export class FiltersFactoryService extends ServiceOperation {
  constructor() {
    super('custom-filters');
  }
}
