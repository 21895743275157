import { Permissions } from '../../classes/Permissions';
let items =  [
  {group: 'Productos y proveedores', items: [
    {label: 'Productos', view:'products', icon: 'local_offer', permission: [Permissions.SUPERADMIN], children: [
      {label: 'Resumen', action: '/main/products/dashboard'},
      {label: 'Listado', action: '/main/products/list'},
      {label: 'Nuevo', action: '/main/products/new'}
    ]},
    {label: 'Proveedores', view: 'providers', icon: 'local_shipping', permission:  [Permissions.SUPERADMIN],  children: [
      {label: 'Resumen', action: '/main/providers/dashboard'},
      {label: 'Listado', action: '/main/providers/list'},
      {label: 'Nuevo', action: '/main/providers/new'},
    ]},
    {label: 'Proveedores de compra', view: 'purchase-provider', icon: 'add_shopping_cart', permission: [Permissions.SUPERADMIN], children: [
      {label: 'Resumen', action: '/main/purchase-provider/dashboard'},
      {label: 'Listado', action: '/main/purchase-provider/list'},
      {label: 'Nuevo', action: '/main/purchase-provider/new'},
      {label: 'Productos', action: '/main/purchase-provider/products'},
      {label: 'Asignar', action: '/main/purchase-provider/assign'}
  ]},
    {label: 'Coste Producto-Proveedor', view:'product-provider-cost', permission:  [Permissions.SUPERADMIN], icon: 'supervisor_account', children: [
      {label: 'Resumen', action: '/main/product-provider-cost/dashboard'},
      {label: 'Listado', action: '/main/product-provider-cost/list'}
    ]},
    {label: 'Producto-Proveedores', view:'product-providers', icon: 'store', permission:  [Permissions.SUPERADMIN], children: [
      {label: 'Resumen', action: '/main/product-providers/dashboard'},
      {label: 'Listado', action: '/main/product-providers/list'}
    ]}
  ]},
  {group:'Gestión', items: [
    {label: 'Tarifas', view:'rates', icon:'euro_symbol',  permission:  [Permissions.SUPERADMIN], children: [
      {label: 'Resumen', action: '/main/rates/dashboard'},
      {label: 'Listado', action: '/main/rates/list'},
      {label: 'Nueva', action: '/main/rates/new'}
    ]},
    {label: 'Compras', view: 'purchases', icon: 'shopping_cart', permission:  [Permissions.SUPERADMIN, Permissions.COMPRAS], children: [
      {label: 'Resumen', action: '/main/purchases/dashboard'},
      {label: 'Historico compras', action: '/main/purchases/orders'},
      {label: 'Historico propuestas', action: '/main/purchases/list'},
      {label: 'Nueva propuesta', action: '/main/purchases/new'},
      {label: 'Proveedor stock', action: '/main/purchases/stock-need'},
    ]},
    {label: 'Grupo de Costes', view:'cost-groups', icon: 'supervisor_account', permission:  [Permissions.SUPERADMIN], children: [
      {label: 'Resumen', action: '/main/cost-groups/dashboard'},
      {label: 'Listado', action: '/main/cost-groups/list'},
      {label: 'Nuevo', action: '/main/cost-groups/new'}
    ]},
    {label: 'Claves de transmisión', view:'transmission-keys', icon: 'vpn_key', permission:  [Permissions.SUPERADMIN], children: [
      {label: 'Gestionar', action: '/main/development/transmission-keys'}
    ]},

  ]},
  {group:'Logística', items: [
    {label: 'Almacenes', view:'warehouses', icon: 'developer_board',  permission:  [Permissions.SUPERADMIN], children: [
      {label: 'Resumen', action: '/main/warehouses/dashboard'},
      {label: 'Listado', action: '/main/warehouses/list'}
    ]},
  ]},
  {group: 'OTROS', items: [
      {label: 'Comerciales', view:'comerciales', icon: 'supervised_user_circle', permission:  [Permissions.SUPERADMIN, Permissions.COMERCIALES], children: [
        {label: 'Resumen', action: '/main/comerciales/dashboard'},
        {label: 'Stock HPLUS', action: '/main/comerciales/hplus'},
        {label: 'Pedidos', action: '/main/comerciales/purchases'},
        {label: 'Propuestas', action: '/main/purchases/list'},
      ]},
    {label: 'Tiendas', view:'shops', icon: 'store',  permission:  [Permissions.SUPERADMIN], children: [
        {label: 'Resumen', action: '/main/shops/dashboard'},
        {label: 'Listado', action: '/main/shops/list'},
        {label: 'Nuevo', action: '/main/shops/new'},
    ]},
    // {label: 'Fabricantes', view:'manufacturer', icon: 'build', permission: 'manufacturers.show' ,children: [
    //     {label: 'Resumen', action: '/main/manufacturer/dashboard'},
    //     {label: 'Listado', action: '/main/manufacturer/list'},
    //     {label: 'Nuevo', action: '/main/manufacturer/new'},
    // ]},
    {label: 'Marcas', view:'brands', icon: 'copyright' ,  permission:  [Permissions.SUPERADMIN], children: [
      {label: 'Resumen', action: '/main/brands/dashboard'},
      {label: 'Listado', action: '/main/brands/list'},
      {label: 'Nuevo', action: '/main/brands/new'},
  ]},
    {label: 'Categorias', view: 'categories', icon:'sort',  permission:  [Permissions.SUPERADMIN], children: [
        {label: 'Resumen', action: '/main/categories/dashboard'},
        {label: 'Listado', action: '/main/categories/list'},
        {label: 'Nuevo', action: '/main/categories/new'},
    ]},
  ]}
]
// items = items.sort( (a:any,b: any) => {
//   if( a.label < b.label) return -1
// })
  export default items;