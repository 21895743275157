const url = 'https://testing-bob-api.salupro.com';
export const environment = {
  production: false,
  apiUrl: url,
  loginRedirect: url + "/oauth/redirect",
  logoutRedirect: url + "/logout",
  firebase: {
    apiKey: "AIzaSyBpnej4sbJxbZcnvsK40vbVpQ5sX36T23w",
    authDomain: "bob-app-b9910.firebaseapp.com",
    databaseURL: "https://bob-app-b9910.firebaseio.com",
    projectId: "bob-app-b9910",
    storageBucket: "bob-app-b9910.appspot.com",
    messagingSenderId: "959318286227",
    appId: "1:959318286227:web:b515bb140cd563de9b5266",
    vapidKey: "BBQzlir1bKSncqqxezXNrQu66hT2Szn_BLf4k2hFQqfo1rGfHkR1CG0n2o0snxs_H1pre9C-hx4bpskoHYYn0_c"
  }
};
