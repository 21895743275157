<div class="fab-container" [style.flexDirection]="reverseColumnDirection?'column-reverse':'column'">
  <button mat-mini-fab class="fab-toggler">
    <i class="material-icons" [@FabToggler]="{value: fabTogglerState}"> {{ fabTogglerState == 'active' ? 'keyboard_arrow_up': 'add'}}</i>
  </button>
  <div [className]="reverseColumnDirection?'column-reverse':'column'" [@speedDialStagger]="buttons.length">

    <ng-container *ngFor="let btn of buttons">

        <button *ngIf="btn.query"  (click)="clickEmitEvent(btn)" mat-mini-fab [matTooltip]="btn.label" class="fab-secondary" color="secondary" >
            <i class="material-icons">{{btn.icon}}</i>
        </button>

        <a *ngIf="!btn.query" mat-mini-fab [matTooltip]="btn.label" class="fab-secondary" color="secondary"[routerLink]="btn.url" >
            <i class="material-icons">{{btn.icon}}</i>
        </a>
    </ng-container>
  </div>
</div>