import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter, map, debounceTime, tap } from "rxjs/operators";
import { ServiceOperation } from "src/app/classes/service-operation";
import { Column } from "src/app/interfaces/column.interface";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { QueryFetcher } from "src/app/classes/QueryFetcher";

@Component({
  selector: "app-chips-autocomplete",
  templateUrl: "./chips-autocomplete.component.html",
  styleUrls: ["./chips-autocomplete.component.scss"]
})
export class ChipsAutocompleteComponent implements OnInit, OnChanges {
  loading: boolean = false;
  optionSearch$: Observable<any>;
  searchInput$: Subject<string> = new Subject();
  chipsListResults: any[] = [];
  @ViewChild('inputSeach', {static: true}) inputSeach: ElementRef;
  @Input() column: Column;
  @Input() operator: string;
  @Input() value: string | number;
  @Output() onChangeValue: EventEmitter<string> = new EventEmitter();
  constructor() {}

  ngOnInit() {
    this.searchOptions('');
    // Stream data search string
    this.searchInput$
      .pipe(
        debounceTime(1000),
        filter(string => !!string)
      )
      .subscribe(string => {
        this.searchOptions(string);
      });
  }

  ngOnChanges(values: SimpleChanges) {
    if(!values.value.currentValue) this.chipsListResults = []
    else {
      if(values.value.firstChange) this.getColumnItemValueById(values.value.currentValue)
    }
  }
  private getColumnItemValueById(id: number | string) {
    if(this.chipsListResults.find(elem => elem.id == id)) return false
    const provider = new ServiceOperation(this.column.search.service);
          provider.get(id)
            .subscribe( item =>  {
              let option: any = {};
                  option.value = item;
              this.setChip({option});
            });
  }

  /**
   * Search into api.
   *
   * @param {string} searchString
   * @memberof FormFieldComponent
   */
  private searchOptions(searchString: string) {

    let field = this.column.search && this.column.search.field ? this.column.search.field : 'name';
    let relations = this.column.search && this.column.search.relations ? this.column.search.relations : [];

    this.loading = true;
    const provider = new ServiceOperation(this.column.search.service);

    const query = new QueryFetcher({
      relations,
      filters: [
        {
          field,
          operator: 'LIKE',
          value: `%${searchString}%`
        }
      ]
    });

    this.optionSearch$ = provider
      .getAll(query)
      .pipe(
        map(response => {
            let data = response.data;
            for (let i in data) {
              if(data[i].translations) data[i].name = data[i].translations.find(t => t.language_id == 1).name
            }
            return data;
        }),
        tap(() => (this.loading = false))
      );
  }

  /**
   * Set a chip into chipsListResults array
   *
   * @param {MatAutocompleteSelectedEvent} item
   * @memberof FormFieldComponent
   */
  setChip(item: MatAutocompleteSelectedEvent | {option: any}) {

    if( !(/in|IN/.test(this.operator)) && this.chipsListResults.length >= 1) return alert("No puedes añadir mas de uno");
    this.chipsListResults.push(item.option.value);
    this.inputSeach.nativeElement.blur();
    this.inputSeach.nativeElement.value = null;
    this.eventOutput();
  }
  /**
   *
   * Remove chips from the list
   * @param {*} chip
   * @memberof ChipsAutocompleteComponent
   */
  removeChip(chip: any) {
    this.chipsListResults = this.chipsListResults.filter(
      element => element.id !== chip.id
    );
    this.eventOutput();
  }
  /**
   *
   * Method emit event output with ids string
   * @memberof ChipsAutocompleteComponent
   */
  eventOutput() {
    let list = this.chipsListResults.map(elem => elem.id).join(',');
    if (!list) list = null;
    this.onChangeValue.emit(list);
  }
}
