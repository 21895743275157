import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-top-bg',
  templateUrl: './top-bg.component.html',
  styleUrls: ['./top-bg.component.scss']
})
export class TopBgComponent implements OnInit {
  @Input('background-color') backgroundColor: string = '#3d4552';
  backgroundUrl: string = '../assets/bg-top.png'
  constructor() { }

  ngOnInit() {
    const date = new Date();
    const month = date.getMonth()

    if(month >= 0 && month <= 1) this.backgroundUrl = '../assets/winter.jpg'
    else if(month >= 2 && month <= 4) this.backgroundUrl = '../assets/spring.jpg'
    else if(month >= 5 && month <= 7) this.backgroundUrl = '../assets/summer.jpg'
    else if(month >= 8 && month <= 11) this.backgroundUrl = 'https://unsplash.com/photos/3-bDL0iRcbI'

  }

}
