import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { MatList } from '@angular/material/list'

interface Config {
  columns: Array<{key: string, label: string, fn?: Function}>
}
declare var document: any;
@Component({
  selector: 'app-alert-table',
  templateUrl: './alert-table.component.html',
  styleUrls: ['./alert-table.component.scss']
})
export class AlertTableComponent implements OnInit {
  @ViewChild('columnWrap', { static: true }) columWrap: ElementRef;
  @ViewChild(MatCard, { read: ElementRef, static: true}) card: ElementRef;
  @ViewChild(MatList, {read: ElementRef, static: true}) list: ElementRef
  alert: boolean = true;
  fullscreen: boolean = false;
  config: Config = {
    columns: [
      { key: 'name', label: 'Nombre'},
      { key: 'date', label: 'Fecha'},
      { key: 'number', label: 'Diferencia', fn: (item: any) =>  {
        const result = (item.in - item.out);
        return result || '';
      } },
      { key: 'status', label: 'Estado', fn: (item: any) =>  {
        const alert = item.in !== item.out;
        return alert ? '<span class="error">x</span>' : '';
      }}
    ]
  };

  data: any = [
   {
     name: 'xxxx',
     date: '20/07/2019',
     in: 3,
     out: 3
   },
   {
    name: 'yyyy',
    date: '22/07/2019',
    in: 5,
    out: 3

  },
  {
    name: 'aaa',
    date: '25/07/2019',
    in: 8,
    out: 8

  },
  {
    name: 'bbb',
    date: '30/07/2019',
    in: 10,
    out: 3
  }
  ];
  constructor(
    private _element: ElementRef
  ) { }

  ngOnInit() {
    this._element.nativeElement.style.setProperty('--columns', this.config.columns.length);
    this.list.nativeElement.setAttribute('dense', true)
  }

  fullScreen() {
    if(this.fullscreen) {
      this._element.nativeElement.classList.remove('fullscreen')
      this.fullscreen = false;
      this.list.nativeElement.setAttribute('dense', true)
    }else {
      this._element.nativeElement.classList.add('fullscreen');
      this.fullscreen = true;
      this.list.nativeElement.removeAttribute('dense')
    }

  }

}
