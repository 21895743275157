
<h1 mat-dialog-title>{{title}}</h1>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>Elige una opción</mat-label>
        <mat-select [(ngModel)]="value">
          <mat-option *ngFor="let option of options" [value]="option">{{ option?.name  }}</mat-option>
        </mat-select>
      </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="flex-end">
    <button mat-button (click)="sendInfo()" [disabled]="!value">ACEPTAR</button>
</mat-dialog-actions>