<!-- Toolbar -->
<mat-toolbar fixed color="primary" class="mat-elevation-z6">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <span cursor-pointer (click)="goTo('/main/')" fxLayout="row" fxLayoutAlign="center center">
    <span>BOB</span>
    <span class="gretting" *ngIf="user" @bounceInUp> ¡Hola de nuevo! <strong>{{user.name}}</strong></span>
  </span>
  <div class="more-actions">

    <div class="weather" fxShow [fxShow.xs]="false" *ngIf="(weather$ | async) as weather">
      <img src="/assets/icons/weather/animated/{{  weather.list[0].weather[0].icon }}.svg">
      <span>{{ weather.city.name}}</span>
      <span>{{ weather.list[0].main.temp | number:'1.0-0'}}ºC</span>
      <span>{{ weather.list[0].weather[0].description}}</span>
      <span class="time">{{ dateTime | date: 'HH:mm:ss'}}</span>
    </div>
    <mat-slide-toggle [checked]="sql_checked"
      [ngClass]="{'hidden': (production && !isSuperAdmin) }" (change)="setSqlCheckedState(); this.modoDebug.next($event.checked);">Modo debug
    </mat-slide-toggle>
    
    <div class="user">

      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="goTo('/main/settings')">
          <mat-icon aria-label="Side nav toggle icon">settings</mat-icon>
          Opciones
        </button>
        <a mat-menu-item routerLink="/main/downloads">
          <mat-icon>cloud_download</mat-icon>
          Descargas
        </a>
        <a mat-menu-item routerLink="/main/development/dashboard">
          <mat-icon>code</mat-icon>
          Desarrollo
        </a>
        <a mat-menu-item routerLink="/main/utils/">
          <mat-icon>build</mat-icon>
          Utilidades
        </a>
        <button mat-menu-item (click)="logOut()">
          <mat-icon>exit_to_app</mat-icon>
          Salir
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item disabled>
          Versión: {{version}}
        </button>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container">
  <!-- Side Nav -->
  <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="true">

    <!-- Toolbar -->
    <mat-toolbar color="primary">
      <span cursor-pointer (click)="goTo('/main/')">BOB</span>
      <span class="more-actions">
        <button type="button" mat-icon-button (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      </span>
    </mat-toolbar>

    <!-- Nav List -->
    <mat-nav-list>
      <!-- Items throw accordion -->
      <ng-container *ngFor="let menu of items">

      <p class="label">{{ menu.group}}</p>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let item of menu.items" [expanded]="currentUrl == item.view ">
          <mat-expansion-panel-header *ngxPermissionsOnly="item.permission">
            <mat-panel-title>
              <span class="display-label">
                <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>{{ item.label }}
              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- Item children -->

          <a class="display-label" mat-list-item *ngFor="let child of item.children" [routerLink]="child.action"
            routerLinkActive="active mat-elevation-z1">
            {{child.label}}
            <mat-icon *ngIf="!(isHandset$ | async)" matTooltipPosition="right"
              matTooltip="Para abrir en un nueva pestaña pulsa con el botón central del ratón">open_in_new</mat-icon>
          </a>


        </mat-expansion-panel>
      </mat-accordion>

      </ng-container>
      

      <!-- <mat-divider></mat-divider>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let item of itemsDebug" [expanded]="currentUrl.includes(item.view)">
          <mat-expansion-panel-header *ngxPermissionsOnly="item.permission">
            <mat-panel-title>
              {{ item.label }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item *ngFor="let child of item.children" [routerLink]="child.action"
            routerLinkActive="active mat-elevation-z1">{{child.label}}</a>

        </mat-expansion-panel>
      </mat-accordion> -->
    </mat-nav-list>
  </mat-sidenav>

  <!-- Sidenav content -->
  <mat-sidenav-content>

    <div class="content">
      <ng-content></ng-content>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>