<div class="header mat-typography" fxLayout="row" fxLayoutAlign="flex-start center">
  <mat-icon>search</mat-icon>
  <span class="title">Filtrar en {{ data.label }}</span>
</div>

<mat-dialog-content>
  <!-- loading -->
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>

  <!-- Content -->
  <div class="mat-typography">
    <mat-form-field>
       <input type="search" matInput placeholder="Buscar" (keyup)="searchString$.next(searchString); output = false" [(ngModel)]="searchString">
    </mat-form-field>

    <mat-radio-group [(ngModel)]="field">
      <mat-radio-button (change)=" searchString = ''; results = [] " [value]="filter.value" *ngFor="let filter of data.filters; index as i">{{ filter.label }}</mat-radio-button>
    </mat-radio-group>

    <output *ngIf="output && searchString" padding-1 margin-1 [ngClass]="{'success': results.length > 0, 'fail': results.length == 0 }" >

      <div *ngIf="results.length > 0; else noResults">
        <div *ngIf="!data.single">
          <p>
            Se {{results.length == 1 ? 'ha' : 'han'}} encontrado {{ results.length }} {{results.length == 1 ? 'resultado' : 'resultados'}} con su busqueda.
          </p>
          {{ this.filter | json}}
        </div>
        <div *ngIf="data.single">
          <mat-radio-group [(ngModel)]="singleOptionId">
            <mat-list role="list">
              <mat-list-item role="listitem" *ngFor="let item of results">
                  <mat-radio-button [value]="item.id">{{ item.translations ? item.translations[0].name : item.name }}</mat-radio-button>
              </mat-list-item>
            </mat-list>
          </mat-radio-group>
        </div>
      </div>

      <ng-template #noResults>
        No hay resultados de busqueda
      </ng-template>
    </output>
  </div>


</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="flex-end">
  <button mat-button (click)="onNoClick(false)">Cancelar</button>
  <button mat-button (click)="onNoClick(true)" color="primary" [disabled]=" (!data.single && results.length == 0 || !searchString) || (data.single && !singleOptionId) ">Aceptar</button>
</mat-dialog-actions>
