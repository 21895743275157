
<div class="wrapper mat-typography">
  <div class="wrapper__card" [routerLink]="['/main/purchases/stock-need']">
    <div class="wrapper__card--left">
      <span>
        <i class="material-icons">
          arrow_upward
        </i>
      </span>
      <h4>Necesidad Alta</h4>
    </div>
    <div class="wrapper__card--right">
      <p *ngIf="loading">
        <mat-spinner [diameter]="30"></mat-spinner>
      </p>
      <p *ngIf="!loading" >
        <strong>{{products.red}} </strong>productos de <strong>{{providers.red}}</strong> proveedores
        <br><br>
        <strong>{{ shops.dental.red }}</strong> Dental <strong>{{ shops.veterinaria.red }}</strong> Veterinaria
      </p>

    </div>
  </div>
  <div class="wrapper__card" [routerLink]="['/main/purchases/stock-need']">
    <div class="wrapper__card--left">
      <span>
        <i class="material-icons">
          arrow_downward
        </i>
      </span>
      <h4>Necesidad Baja</h4>
    </div>
    <div class="wrapper__card--right">
      <p *ngIf="loading"> <mat-spinner [diameter]="30"></mat-spinner> </p>
      <p *ngIf="!loading">
        <strong>{{products.yellow}} </strong>productos de <strong>{{providers.yellow}}</strong> proveedores
        <br><br>
        <strong>{{ shops.dental.yellow }}</strong> Dental <strong>{{ shops.veterinaria.yellow }}</strong> Veterinaria
      </p>

    </div>
  </div>
</div>