import { Component, OnInit } from "@angular/core";
import { PurchaseOrdersService } from "../../services/purchase-orders.service";
import { QueryFetcher } from '@/classes/QueryFetcher';
import { ProductDemandService } from '@/services/product-demand.service';
import * as moment from 'moment';

@Component({
  selector: "app-resume-provider-with-stock-need",
  templateUrl: "./resume-provider-with-stock-need.component.html",
  styleUrls: ["./resume-provider-with-stock-need.component.scss"]
})
export class ResumeProviderWithStockNeedComponent implements OnInit {
  loading: boolean = false;
  data: Array<any> = [];
  products: {red: number, yellow: number} = { red: 0, yellow: 0}
  providers: {red: number, yellow: number} = { red: 0, yellow: 0}
  shops: any = {
    dental: { red: 0, yellow: 0},
    veterinaria: { red: 0, yellow: 0}
  }
  constructor(
    private _productDemandService: ProductDemandService,
    private _purchaseService: PurchaseOrdersService) {}

  ngOnInit() {
    this.getProviderWithStockNeed();
  }


  getProviderWithStockNeed() {
    this.loading = true;
    this._purchaseService.providersWithStockNeed().subscribe(
      response => {
        this.loading = false;
        if(!response.data.length) return this.calculate();

        this.data = response.data;
        this.data.forEach(item => {
          if(item.red > 0)
            {
              this.products.red += item.red 
              this.providers.red++
              if(item.categories) {
                if(item.categories.includes("50069")) this.shops.veterinaria.red++
                if(item.categories.includes("39000")) this.shops.dental.red++
              }
            }
          if(item.yellow > 0 && item.red == 0) {
            this.products.yellow += item.yellow
            this.providers.yellow++
            if(item.categories) {
              if(item.categories.includes("50069")) this.shops.veterinaria.yellow++
              if(item.categories.includes("39000")) this.shops.dental.yellow++
            }
          }
          
        })
      },
      error => (this.loading = false)
    );
  }

  calculate() {
    this.loading = true;
    const query: QueryFetcher = new QueryFetcher();
    query.filters = [{field: 'product.stockNeed.order_date', operator: '=', value: moment().format('YYYY-MM-DD')}]
    query.force = 'feel the force'
    this._productDemandService.getAll(query)
      .subscribe(
        () => {
          this.getProviderWithStockNeed()
          this.loading = false
        }
      )
  }
}
