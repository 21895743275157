import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from "@angular/core";
import { fabAdvancedAnimations } from './fab-advanced.animation';
import { TouchSequence } from 'selenium-webdriver';
import { QueryFetcher } from '@/classes/QueryFetcher';


export interface FabButton {
  icon: string;
  label: string;
  url: string;
  query?: QueryFetcher
}
@Component({
  selector: "app-fab-advanced",
  templateUrl: "./fab-advanced.component.html",
  styleUrls: ["./fab-advanced.component.scss"],
  animations: fabAdvancedAnimations
})
export class FabAdvancedComponent implements OnInit {
  @Input("reverse-column-direction") reverseColumnDirection: boolean = true;
  @Input("buttons") fabButtons: FabButton[];
  @Input() card: HTMLElement;
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter();

  buttons: FabButton[] = [];
  fabTogglerState = "inactive";
  constructor() {}

  ngOnInit() {

    this.card.onmouseenter = () => this.showItems();
    this.card.onmouseleave = () => this.hideItems();
  }
  showItems() {
    this.fabTogglerState = "active";
    this.buttons = this.fabButtons;
  }
  hideItems() {
    this.fabTogglerState = "inactive";
    this.buttons = [];
  }
  clickEmitEvent(btn) {
    this.onButtonClick.emit({
      url: btn.url,
      query: btn.query
    })
  }
}
