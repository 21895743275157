import { Injectable } from '@angular/core';
import { ServiceOperation } from '../classes/service-operation';

@Injectable({
  providedIn: 'root'
})
export class ProductDemandService extends ServiceOperation {

  constructor() {
    super('product-demand')
  }
}
