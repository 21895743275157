import { Filter } from "../interfaces/filter.interface";

export class QueryFetcher {
  orders: Array<{field: string, direction: string}>;
  filters: Array<Filter>;
  formula: string;
  take: number;
  skip: number;
  relations: Array<string>;
  exportFields: any;
  force?: any;
  last_order_date?: string;
  last_order_date_operator?: string;

  getFilterByField?: Function = (field: string) =>  this.filters.find( filter => filter.field === field)

  constructor(options?) {
    options = options || {};
    this.take = options['take'] || 50;
    this.skip = options['skip'] || 0;
    this.relations = options['relations'] || [];
    this.filters = options['filters'] || [];
    this.orders = options['orders'] || [];
  }

}
