import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
/*Modal*/
import { ModalFilterComponent } from '../../components/modal-filter/modal-filter.component';
@Component({
  selector: 'btn-modal-simple-filter',
  templateUrl: './btn-modal-simple-filter.component.html',
  styleUrls: ['./btn-modal-simple-filter.component.scss']
})
export class BtnModalSimpleFilterComponent implements OnInit {
  @Input('service') serviceS;
  @Input() filters: Array<{label: string, value: string, operator?: string}>
  @Input() relations: Array<string>
  @Input() label: string
  @Output() onSuccess: EventEmitter<any> = new EventEmitter()

  constructor(
      public dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  /*Open modal*/
  openDialog() {
    const dialogRef = this.dialog.open(ModalFilterComponent, {
      disableClose: true,
      maxWidth: '600px',
      data: {
        label: this.label,
        service: this.serviceS,
        filters: this.filters,
        relations: this.relations
        }
    });
    // Event called when modal is closed.
    dialogRef.afterClosed().subscribe(result => {
      // If result is true that means there are any results.
      if(result) this.onSuccess.emit(result.queryTable)
    });
  }


}
