import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AngularmaterialModule} from './angularmaterial.module';
import { NgxLocalStorageModule, LocalStorageService} from 'ngx-localstorage';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { NgxPermissionsModule, NgxPermissionsService, NgxRolesService  } from 'ngx-permissions';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Crypto } from './classes/Crypto';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging'
import { ApiInterceptor } from './services/api.interceptor';
import { ComponentsModule } from './components/components.module';

export let InjectorInstance: Injector;
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ComponentsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    NgxPermissionsModule.forRoot(),
    FormsModule,
    NgxLocalStorageModule.forRoot({
      prefix: 'app-salupro'
    }),
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularmaterialModule,
    FlexLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: APP_INITIALIZER,
      useFactory: (ps: NgxPermissionsService, pr:NgxRolesService,  storage: LocalStorageService) => async function() {
        // Array Permissions
        let PERMISSIONS : Array<string> = []
        let ROLES: any = {}
        // Instance crypto
        const crypto = new Crypto()
        // Local User
        const localUser = storage.get('user')
        // If local users exists
        if(localUser) {
          // Generate Headers
          const query = {
            headers: new Headers({
              'Authorization': `Bearer ${crypto.descrypt(localUser).token}`
            })
          }          
          try {
            const url = `${environment.apiUrl}/user-info${ environment.production ? '': '?XDEBUG_SESSION_START=PHPSTORM'}`
            const user: any = await fetch(url, query).then( res => {
              
              // If status is 401 tokens is bad
              if(res.status == 401) {
                // Remove user data from localstorage
                storage.remove('user')
                // Force reload to login
                window.location.href = '/login'
              }
              else return res.json()
            })
            //Roles
            storage.set('user_roles', user.roles)
            for(const r of user.roles) {
              ROLES[r.id] = []
            }
            // set permissions
            PERMISSIONS = user.permissions
          }catch(e) {
            return false
          }
        }

        // Load permissions library
        pr.addRoles(ROLES)
        return ps.loadPermissions(PERMISSIONS)
        

      },
      deps: [ NgxPermissionsService, NgxRolesService, LocalStorageService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}
