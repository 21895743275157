<div class="header mat-typography" fxLayout="row" fxLayoutAlign="flex-start center">
  <mat-icon>warning</mat-icon>
  <span class="title">{{ title }}</span>
</div>
<mat-dialog-content>
  <div class="mat-typography">
    {{ message }}
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="flex-end">
  <button mat-button (click)="onNoClick(false)">CANCELAR</button>
  <button mat-button (click)="onNoClick(true)" color="primary">ACEPTAR</button>
</mat-dialog-actions>
