import { Component, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SectionCard } from '@/interfaces/section.interface';
import { QueryFetcher } from '@/classes/QueryFetcher';

@Component({
  selector: 'app-card-dashboard',
  templateUrl: './card-dashboard.component.html',
  styleUrls: ['./card-dashboard.component.scss']
})
export class CardDashboardComponent {
  @Input() section: SectionCard;

  constructor(
    private router: Router
  ) {
   }


  /**
 *  Go to url
 *
 * @param {string} url
 * @param {QueryFetcher} query
 * @memberof PurchasesComponent
 */
toGo(url: string, query: QueryFetcher) {

  const navigationExtras: NavigationExtras = {
    queryParams: { 'f': JSON.stringify(query)}
  };
  this.router.navigate([url], navigationExtras )
}

}
