<div class="header mat-typography" fxLayout="row" fxLayoutAlign="flex-start center" [ngClass]="{'error': data.error }">
  <mat-icon>warning</mat-icon>
  <span class="title">{{ title }}</span>
</div>
<mat-dialog-content>

  <json-editor [data]="data.message" [options]="{modes: ['code']}"  *ngIf="data.type == 'json' "></json-editor>
  <div class="mat-typography" [innerHTML]="message" *ngIf="data.type != 'json' "></div>

</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="flex-end">
  <button mat-button (click)="onNoClick(true)" [color]=" data.error ? 'warn' : 'primary' ">ACEPTAR</button>
</mat-dialog-actions>
