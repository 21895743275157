import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { LocalStorageService } from 'ngx-localstorage';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WeatherService {
  apiKey: string = 'caddaaee5756955f30e84bf25143db6b';
  url: string = 'https://api.openweathermap.org/data/2.5/forecast';
  city: string = this.storage.get('weather-city') || 'Madrid';
  weather: ReplaySubject<any> = new ReplaySubject()
  httpWithoutInterceptor: HttpClient
  constructor(
    private snackBar: MatSnackBar,
    private storage: LocalStorageService,
    private httpBackend: HttpBackend
  ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
    this.getApiWeather();
  }

  getWeather(): Observable<any> {
    return this.weather;
  }
  getApiWeather() {
    let url = `${this.url}?appid=${this.apiKey}&q=${this.city}&lang=es&units=metric&cnt=7`
     this.httpWithoutInterceptor.get(url).subscribe(weather => this.weather.next(weather))
  }
  setCity(city) {
    this.storage.set('weather-city', city);
    this.city = city;
    this.snackBar.open(
      'Ciudad actualizada correctamente',
      null,
      {duration: 4000});
    this.getApiWeather();
  }
}
