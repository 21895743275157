<!-- Toolbar -->
<mat-toolbar class="default">
  <span [@bounceInLeft] fxLayout="row" fxLayoutAlign="center center">
    <button mat-icon-button *ngIf="back" (click)="location.back()"><mat-icon>arrow_back</mat-icon></button>
    <mat-icon *ngIf="icon && !back" class="icon">{{icon}}</mat-icon>
    <span> {{title}} </span>
  </span>
  <div class="actions">
     
    <button *ngxPermissionsOnly="exportPermission" mat-button [hidden]="!exportButtom "(click)="onClick()"> <mat-icon>cloud_download</mat-icon> Exportar</button>
    <ng-content></ng-content>
    <!-- Scroll -->
    <button [ngClass]="{'active': scroll_secuencial}" mat-icon-button [matTooltip]="scroll_secuencial ? 'Scroll secuencial' : 'Scroll normal' " *ngIf="scroll_secuencial != null" [routerLink]="[ '/main/settings' ]">
      <mat-icon>list</mat-icon> 
    </button>
  </div>
</mat-toolbar>
