import { Directive} from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[alwaysPositive]',
  providers: [NgModel]
})
export class AlwaysPositiveDirective {

  constructor(
    private ngModel: NgModel
  ) { }

  ngOnInit() {
    this.ngModel.valueChanges.subscribe( (value) => {
      setTimeout(() => this.ngModel.update.emit(Math.abs(value)), 0)
    });
  }

}
