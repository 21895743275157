import { Component, OnInit, Input} from '@angular/core';
export interface Step {
  name : string
  active?: boolean
  value?: string
}

@Component({
  selector: 'app-steper-balls',
  templateUrl: './steper-balls.component.html',
  styleUrls: ['./steper-balls.component.scss']
})
export class SteperBallsComponent implements OnInit {
  @Input() Steps: Array<Step> = [];

  constructor() { }

  ngOnInit() {
  }

}
