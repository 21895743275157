<!-- Card List -->
<!-- <mat-card>
  <img mat-card-image [src]="section.image">
  <mat-card-content>
    <h2>{{section.title}}</h2>
    <p>{{section.description}}</p>
  </mat-card-content>
  <mat-divider></mat-divider>

  <mat-card-actions *ngIf="section.url && !section.actions">
    <a mat-button [routerLink]="section.url" color="primary" *ngxPermissionsOnly="section.permission"> ENTRAR </a>
  </mat-card-actions>

  <mat-card-actions *ngIf="section.actions && !section.url">
    <ng-container *ngFor="let action of section.actions">
      <ng-template [ngIf]="action.query" [ngIfElse]="withoutQuery">
          <a mat-button (click)="toGo(action.url, action.query)" color="primary" *ngxPermissionsOnly="section.permission">
              {{ action.label | uppercase }}
          </a>
      </ng-template>
      <ng-template #withoutQuery>
          <a mat-button [routerLink]="action.url" color="primary"  *ngxPermissionsOnly="section.permission">
              {{ action.label | uppercase }}
          </a>
      </ng-template>
    </ng-container>
  </mat-card-actions>
</mat-card> -->

<div class="card" *ngxPermissionsOnly="section.permission" #card [routerLink]="section.url">
  <h2>{{section.title}}</h2>
  <a *ngIf="section.url">
    <i class="material-icons right">
      arrow_forward
    </i>
  </a>
  <div class="pic" [style.backgroundImage]=" 'url('+section.image+')' "></div>
  <app-fab-advanced
    *ngIf="section.actions"
    [buttons]="section.actions"
    [card]="card"
    (onButtonClick)="toGo($event.url, $event.query)"
    >
  </app-fab-advanced>
  <!-- <div class="actions">
    <i class="material-icons" matTooltip="Añadir">
      filter_vintage
    </i>
    <i class="material-icons" matTooltip="Crear">
      flip_camera_android
    </i>
    <i class="material-icons" matTooltip="Listado">
      filter_drama
    </i>
  </div> -->
  <button>
  </button>