import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'table-selector-all',
  templateUrl: './table-selector-all.component.html',
  styleUrls: ['./table-selector-all.component.scss']
})
export class TableSelectorAllComponent implements OnInit {

  @Input('items-selected') itemsSelected : any
  @Input('items-all') itemsAll: any
  @Output() onSelectedAll: EventEmitter<boolean> = new EventEmitter();
  selectedAll : boolean = false;
  constructor() { }

  ngOnInit() {
  }

  toggleSelectAll() {
    this.selectedAll = !this.selectedAll
    this.onSelectedAll.emit(this.selectedAll)
  }
}
