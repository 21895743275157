<main *ngIf="(weather$ | async) as weather">
    <h2 mat-dialog-title><mat-icon>place</mat-icon> {{ weather.location.name }}, pronostico para hoy.</h2>
    <mat-dialog-content class="mat-typography">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span fxLayout="row" fxLayoutAlign="center flex-start"><h1 style="font-size: 4em">{{weather.current.temp_c }} </h1>ºC</span>
        <span><img [src]="weather.current.condition.icon"></span>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center"><h3> {{ weather.current.condition.text }} </h3></div>
      <mat-divider margin-bottom-1></mat-divider>
      <div fxLayout="row">
        <span fxFlex="1 1 auto">Precipitaciones: <strong>{{ weather.current.precip_mm }} mm</strong></span>
        <span fxFlex="1 1 auto">Sensación termica <strong>{{ weather.current.feelslike_c }}ºC</strong></span>
        <span fxFlex="1 1 auto">Viento <strong>{{ weather.current.wind_kph }} km/h</strong></span>
      </div>
      <mat-divider margin-top-1></mat-divider>
      <mat-list>
        <h3 mat-subheader>Predicción</h3>
        <mat-list-item *ngFor="let forecast of weather.forecast.forecastday; index as i" [hidden]="i === 0">
          <img mat-list-icon [src]="forecast.day.condition.icon"/>
          <h4 mat-line><strong>{{forecast.date | date:'EEEE' | uppercase}}: </strong> <small>Max: </small> <strong>{{forecast.day.maxtemp_c}}ºC</strong> <small> Min: </small> <strong>{{forecast.day.mintemp_c}}ºC </strong></h4>
          <p mat-line> {{ forecast.day.condition.text  }} </p>
        </mat-list-item>
      </mat-list>
    </mat-dialog-content>
</main>
