import * as aesjs from 'aes-js'

export class Crypto {
  key: Array<number>
  iv: Array<number>
  constructor() {
    this.key = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16 ];
    this.iv = [ 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36 ];
  }

  encrypt(text) {
    const textBytes = aesjs.utils.utf8.toBytes(JSON.stringify(text));

    const aesOfb = new aesjs.ModeOfOperation.ofb(this.key, this.iv);
    let encryptedBytes = aesOfb.encrypt(textBytes);

    // To print or store the binary data, you may convert it to hex
    const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes)


    return encryptedHex
  }
  descrypt(encryptedHex){
    const encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);
    // The output feedback mode of operation maintains internal state,
    // so to decrypt a new instance must be instantiated.
    const aesOfb = new aesjs.ModeOfOperation.ofb(this.key, this.iv);
    const decryptedBytes = aesOfb.decrypt(encryptedBytes);

    // Convert our bytes back into text
    const decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return JSON.parse(decryptedText)
  }
}
