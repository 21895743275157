import { ModalSimpleInputComponent } from "@/components/modal-simple-input/modal-simple-input.component";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";
import { Column } from "src/app/interfaces/column.interface";
import { Filter } from "src/app/interfaces/filter.interface";

@Component({
  selector: "app-form-field",
  templateUrl: "./form-field.component.html"
})
export class FormFieldComponent implements OnInit {
  disabled: boolean = false;
  @Input() columns: Array<Column>;
  @Input() filter: any;
  @Output() sendFilter: EventEmitter<any> = new EventEmitter();
  @Output() remove: EventEmitter<any> = new EventEmitter();
  operators: Array<{ label: string; value: string }> = [
    { label: "=", value: "=" },
    { label: ">=", value: ">=" },
    { label: "<=", value: "<=" },
    { label: ">", value: ">" },
    { label: "<", value: "<" },
    { label: "!=", value: "!="},
    { label: "IN", value: "IN" },
    { label: "NOT IN", value: "NOT IN" },
    { label: "NOT LIKE", value: "not like" },
    { label: "LIKE", value: "LIKE" },
    { label: "IS NULL", value: "is null" },
    { label: "IS NOT NULL", value: "is not null" },
    { label: "LIKE BINARY", value: "LIKE BINARY"}
  ];
  columnSelected: Column;
  constructor(
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // If filter is true in the input, set columnSelected
    if (this.filter.field) {
      this.columnSelected = this.columns.find(
        (elem: Column) => elem.value == this.filter.field
      );
      if (this.columnSelected)
        this.disabled = this.columnSelected.disabledFilter;
    }
  }

  /**
   * Check operator set null if operator is 'is null' or 'not null'
   *
   * @memberof FormFieldComponent
   */
  checkOperator() {
    if (
      this.filter.operator == 'is null' ||
      this.filter.operator == 'is not null'
    ) {
      this.filter.value = 'null';
    } else {
      this.filter.value = null;
    }
    this.send();
  }
  /**
   * Event fired when change select columns
   *
   * @param {*} column
   * @memberof FormFieldComponent
   */
  changeColumn(column) {
    const selected_column: Column = this.columns.find(
      (elem: Column) => elem.value == column.value
    );

    // Clear value
    this.filter.value = null;
    // Set operator
    this.filter.operator = selected_column.operator || "=";
    this.columnSelected = selected_column;

    //this.send();
  }
  /**
   * Change and format date
   *
   * @param {*} date
   * @memberof FormFieldComponent
   */
  changeDate(date) {
    this.filter.value = moment(date.value).format("YYYY-MM-DD");
    this.send();
  }
  /**
   * Method that send a event emiter with the filter and press ENTER boolean
   *
   * @param {boolean} [withEnter=false]
   * @memberof FormFieldComponent
   */
  send(withEnter = false) {
    // if(this.columnSelected.search) {
    //   if(this.filter.operator == '=' || this.filter.operator == 'IN') {
    //    if(!this.filter.field.match(/.id$/)) {
    //     this.filter.field = this.filter.field.replace(/^(.+\.).*$/, '$1id')
    //    }
    //   }else this.filter.field = this.columnSelected.value
    // }
    this.sendFilter.emit({ filter: this.filter, enter: withEnter });
  }
  /**
   * Method that emit an event for clear the filter.
   *
   * @memberof FormFieldComponent
   */
  removeFilter() {
    this.remove.emit(this.filter);
  }


  textarea() {
    if(this.filter.operator != 'IN') return false;
    const dialogRef = this.dialog.open(ModalSimpleInputComponent, {data: { title: 'Cuadro de texto',type: 'textarea-simple', valueString: this.filter.value}});

    dialogRef.afterClosed().subscribe(result => {
      if(result) this.filter.value = result;
    });
  }

  // Event press enter send Filter
  @HostListener("keyup", ["$event"]) onKeyUp(e) {
    if (e.keyCode === 13) this.send(true);
  }
}
