
<h1 mat-dialog-title>Información de {{ product.maktx }}</h1>
<div mat-dialog-content class="main">
    <ng-template [ngIf]="product.hefame_producto_localizacion.length > 0" [ngIfElse]="noData">
        <ng-container *ngFor="let warehouse of product.hefame_producto_localizacion">
            <mat-list>
                <h3 mat-subheader>Almacen &nbsp; <strong *ngIf="warehouse.hefame_producto_almacen as w_lote"> {{w_lote.name}} <{{w_lote.lgtyp}}> </strong></h3>
                <ng-template [ngIf]="warehouse.hefame_producto_lote.length > 0" [ngIfElse]="noLote">
                    <mat-list-item *ngFor="let lote of warehouse.hefame_producto_lote">
                        <mat-icon mat-list-icon>folder</mat-icon>
                        <h2 mat-line>Lote: <strong>{{ lote.charg}}</strong></h2>
                        <h4 mat-line>Cantidad: <strong>{{lote.gesme}}</strong></h4>
                        <p mat-line>Fecha de caducidad: <strong>{{lote.vfdat | date:'mediumDate'}}</strong> </p>
                      </mat-list-item>
                </ng-template>
                <ng-template #noLote>
                  <h2 class="mat-h2" text-center>No hay lotes</h2>
                </ng-template>
              </mat-list>
            <mat-divider></mat-divider>
        </ng-container>
    </ng-template>
    <ng-template #noData>
      <no-data margin-1></no-data>
    </ng-template>

</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
  <button mat-button mat-dialog-close color="warn" >Cerrar</button>
</div>
