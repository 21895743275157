<mat-card (mouseenter)="alert = false"  (mouseleave)="alert = true" [style.backgroundColor]="alert ? '#0277bd' : null ">

   <button *ngIf="!alert" class="menu" mat-icon-button (click)="fullScreen()" ><mat-icon>{{ fullscreen ? 'fullscreen_exit' : 'fullscreen'}}</mat-icon></button>
  <!-- Front -->
  <mat-card-content  fxLayout="row" fxLayoutAlign="center center" [hidden]="!alert">
    <div fxLayout="column" fxLayoutAlign="center center" class="alert">
      <h3>20</h3>
      <p>Productos con incidencia</p>
    </div>
  </mat-card-content>
  <!-- Back -->
  <mat-card-content [hidden]="alert">
    <h3>Datos</h3>
    <mat-list role="list">
      <mat-list-item role="listitem" class="column-grid">
        <section #columnWrap>
          <div *ngFor="let item of config.columns">{{ item.label }}</div>
        </section>
      </mat-list-item>
      <mat-list-item role="listitem" class="column-grid" *ngFor="let item of data">
        <section>
          <div *ngFor="let column of config.columns" [innerHTML]="column.fn ? column.fn(item) : item[column.key]"></div>
        </section>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
