import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { SessionService } from './session.service';
import { catchError, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsersService } from './users.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from 'src/environments/environment';
import { ModalAlertComponent } from '@/components/modal-alert/modal-alert.component';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private _sessionService: SessionService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _usersService: UsersService,
    private _ngxPermissionsService: NgxPermissionsService,
    private _dialog: MatDialog
  ) {}
  
  setDebugger(request) {
    return request.url.includes('?') ? request.url + '&XDEBUG_SESSION_START=PHPSTORM' : request.url + '?XDEBUG_SESSION_START=PHPSTORM'
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      url: environment.production ? request.url : this.setDebugger(request),
      withCredentials: true,
      setHeaders: {
        ContentType: 'application/json',
        Authorization: `Bearer ${this._sessionService.getToken}`
      }
    });
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.gestionErrors(error)
        return throwError(error)
      })
    )
  }

  gestionErrors(error: HttpErrorResponse) {
    const duration = 4000
    switch(error.status) {
      case 400:
        //this._snackBar.open(error.error.message, null, {duration});
        this.modalError(error.error.message);
      break;
      case 401:
        this._snackBar.open('401 Usuario no autorizado', null, {duration});
        this._sessionService.logOut()
        this._router.navigate(['/login'])
      break;
      case 403:
        this._snackBar.open('403 No tiene permiso para acceder a este recurso. Intentanlo de nuevo', null ,{duration})
        this._usersService.me().subscribe( (user: any) => {
          this._ngxPermissionsService.loadPermissions(user.permissions)
        })
      break;
      case 404:
        this._snackBar.open('404 Recurso no encontrado', null, {duration})
      break;
      default:
        this.modalError(error.message)
    }
  }

  modalError(message: string) {
    this._dialog.open(ModalAlertComponent, {
       data: {
         error: true,
         title: 'Oops ha ocurrido un error',
         message,
       }
    })
  }
}
