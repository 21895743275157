import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[hoverable]',

})
export class HoverableDirective {

  constructor(
    private renderer: Renderer2,
    private element: ElementRef) {
      this.renderer.setStyle(this.element.nativeElement, 'transition', 'transform 500ms ease-out')
    }

    @HostListener('mouseover') onMouseOver() {
      this.renderer.setStyle(this.element.nativeElement, 'transform', 'scale(1.05)')
      this.renderer.setStyle(this.element.nativeElement, 'transition', 'transform 500ms ease-out')
    }
    @HostListener('mouseout') onMouseOut() {
      this.renderer.setStyle(this.element.nativeElement, 'transform', 'scale(1)')
    }

}
