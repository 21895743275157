<mat-card>
  <button (click)="closeFilter()" class="close-button" mat-mini-fab> <mat-icon>close</mat-icon></button>
  <mat-card-content>
    <mat-progress-bar mode="indeterminate" [style.visibility]="loading ? 'visible': 'hidden' "></mat-progress-bar>
    <!-- Fields -->
    <app-form-field *ngFor="let filter of filters" [columns]="columnsOrdered" [filter]="filter"
      (sendFilter)="newOrUpdateFilter($event)" (remove)="removeFilter($event)">
    </app-form-field>
    <!-- Button add -->
    <button mat-icon-button (click)="addNewFilter()">
      <mat-icon>add</mat-icon>
    </button>
  </mat-card-content>
  <mat-divider></mat-divider>
  <div fxlayout="row" fxLayoutAlign="center center">

    <mat-chip-list class="list-filters" #chipList aria-label="Filters"
      *ngIf="filtersSalved && filtersSalved.length > 0;else zeroFilters" fxFlex="1 1 auto">
      <mat-chip *ngFor="let filter of filtersSalved" [removable]="true"
        (click)="sendFilterThrowSalved( filter.storage )"
        (removed)="deleteChip ? removeSalvedFilter(filter.id) : setEditFilter(filter) ">
        {{ filter.name }}
        <mat-icon matChipRemove *ngIf="deleteChip">cancel</mat-icon>
        <mat-icon matChipRemove *ngIf="editChip">edit</mat-icon>
      </mat-chip>
    </mat-chip-list>

    <!-- Button more -->
    <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="filtersSalved && filtersSalved.length > 0 ">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="toggleChips('edit')">
        <mat-icon>edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item (click)="toggleChips('delete')">
        <mat-icon>delete</mat-icon>
        <span>Borrar</span>
      </button>
    </mat-menu>

  </div>

  <!-- Formula -->
  <!-- <mat-form-field padding-1>
    <input type="text" matInput placeholder="Fórmula" name="formula" [(ngModel)]="formula" [disabled]="!formula">
  </mat-form-field> -->

  <ng-template #zeroFilters>
    <p padding-1><small>No hay filtros guardados</small></p>
  </ng-template>
  <mat-divider></mat-divider>

  <mat-card-actions fxLayout="row">
    <div fxFlex="1 1 auto">
      <button mat-button color="primary" [disabled]="!(isFilterOk$ | async) || filters.length == 0"
        (click)="sendFilter()">FILTRAR</button>
      <button color="warn" mat-button [disabled]="filters.length == 0" (click)="removeAllFilters()">QUITAR TODOS LOS
        FILTROS</button>
      <button mat-icon-button (click)="openModalHelp('filter')">
        <mat-icon>help</mat-icon>
      </button>
    </div>
    <div fxLayout="row">
      <button color="primary" mat-button [disabled]="!(isFilterOk$ | async) || filters.length == 0"
        (click)="openModalInput()" *ngIf="!editChip">GUARDAR FILTRO</button>
      <mat-form-field *ngIf="editChip">
        <input matInput placeholder="Editar nombre del filtro" [(ngModel)]="editChip.name" [disabled]="!editChip.id">
      </mat-form-field>
      <button color="primary" mat-button *ngIf="editChip" (click)="editFilter()" [disabled]="!editChip.id">EDITAR
        FILTRO</button>

    </div>

  </mat-card-actions>
</mat-card>