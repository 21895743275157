import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-select',
  templateUrl: './modal-select.component.html',
  styleUrls: ['./modal-select.component.scss']
})
export class ModalSelectComponent {

  title: string;
  options: any[] = []
  value: any;
  constructor(
    public dialogRef: MatDialogRef<ModalSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title || 'Elige una opción';
    this.options = data.options || [];
   }

   sendInfo() {
     this.dialogRef.close(this.value);
   }


}
