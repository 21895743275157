<div class="header mat-typography" fxLayout="row" fxLayoutAlign="flex-start center">
  <span class="title">{{title}}</span>
</div>
<mat-dialog-content>
  <textarea [(ngModel)]="valueString" *ngIf="type == 'textarea-simple'" cols="30" rows="10"></textarea>
  <editor *ngIf="type == 'textarea'"  [init]="{menubar: false}" apiKey="7lx1n6uppbyjcen3lz7q6b1drxxdkrrqdqewprsau7k3iflj" [(ngModel)]="valueString"></editor>
  <div class="mat-typography">
    <mat-form-field *ngIf="type == 'input' ">
      <input type="search" matInput [placeholder]="place" [(ngModel)]="valueString">
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="flex-end">
  <button mat-button (click)="dialogRef.close()">Cancelar</button>
  <button mat-button (click)="sendInfo()" color="primary" [disabled]=" !valueString ">Aceptar</button>
</mat-dialog-actions>