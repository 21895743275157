import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  NgZone
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ModalHelpComponent } from "../../components/modal-help/modal-help.component";
import { Filter } from "src/app/interfaces/filter.interface";
import { ModalSimpleInputComponent } from '../modal-simple-input/modal-simple-input.component';
import { FiltersFactoryService, Filterfactory } from '../../services/filters-factory.service';
import { QueryFetcher } from '@/classes/QueryFetcher';
import { BehaviorSubject } from "rxjs";
import { map } from 'rxjs/operators';
import { ModalDeleteAdvanceComponent } from 'src/app/components/modal-delete-advance/modal-delete-advance.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: "app-filter-table",
  templateUrl: "./filter-table.component.html",
  styleUrls: ["./filter-table.component.scss"]
})
export class FilterTableComponent implements OnInit, OnChanges {
  columnsOrdered: Array<any>
  @Input() set columns(columns: Array<any>){

      console.log('Ordered columns')
      const c = JSON.parse(JSON.stringify(columns));
      this.columnsOrdered = c.sort((a,b) => {
        if(a.label > b.label) return 1
        else return -1
      })
  }
  @Input() opened: boolean;
  @Output() eventFilter: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() filters: Array<Filter>;
  formula: string;
  isFilterOk$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  filtersSalved: Array<Filterfactory>;
  deleteChip: boolean = false;
  editChip: boolean | any = false;
  loading = false;
  constructor(
    private dialog: MatDialog,
    private _FiltersFactoryService: FiltersFactoryService,
    private _snackBar: MatSnackBar,
    private _ngZone: NgZone
    ) {}

  /** Lifecycle */
  ngOnInit() {
    if (!this.filters || this.filters.length == 0)
    this.filters = [{ id: 1, field: null, operator: null, value: null }];
    else this.calculateFormula();
    this.getAllFilter();
    if (this.deleteChip || this.editChip) {
      this.deleteChip == false;
      this.editChip == false;
    }
  }
  ngOnChanges(simple: SimpleChanges) {
    if(this.opened) document.querySelector('.content-main')['style'].display = 'block';
    else document.querySelector('.content-main')['style'].display = 'flex';

    this.filters = this.filters.map((filter, index) => {
      filter.id = (index + 1)
      return filter;
    });
    this.calculateFormula();
  }

  /**
   * Add new id filter to Filters Array.
   */
  addNewFilter() {
    this.filters.length === 0
      ? (this.filters = [{ id: 1, field: null, operator: null, value: null }])
      : (this.filters = [
          ...this.filters,
          {
            id: this.filters[this.filters.length - 1].id + 1,
            field: null,
            operator: null,
            value: null
          }
        ]);

    this.checkFilter();
  }

  /* Event update info from line filter  { filter , enter: boolean} */
  newOrUpdateFilter({ filter, enter }) {
    // Update filter
    this.filters = this.filters.map(elem => {
      if (elem.id == filter.id) elem = filter;
      return elem;
    });

    this.calculateFormula();
    this.checkFilter();
    // If enter is true submit form
    if (enter) this.sendFilter();
  }

  /* Remove Line filter */
  removeFilter(filter: Filter) {
    // Filter array by id.
    this.filters = this.filters.filter(elem => elem.id != filter.id);
    this.calculateFormula();
    this.checkFilter();
    this.sendFilter(false);
  }
  /* Remove all filters */
  removeAllFilters() {
    this.filters = [];
    this.calculateFormula();
    this.checkFilter();
    this.sendFilter(false);
  }
  /* Formula */
  calculateFormula() {
    this.formula = this.filters.map(elem => elem.id).join(" AND ");
  }

  /* Check if is able to push in filter button */
  checkFilter() {
    let flag = true;
    for (let i in this.filters) {
      if (
        !this.filters[i].field ||
        !this.filters[i].operator ||
        !this.filters[i].value
      ) {
        flag = false;
        break;
      }
    }
    flag ? this.isFilterOk$.next(true) : this.isFilterOk$.next(false);
  }

  /* Output event send Filter*/
  sendFilter(hide: boolean = true) {
    this.eventFilter.emit({
      filters: this.filters,
      formula: this.formula,
      hide: hide
    });
  }

  /**
   * Close Filter
   *
   * @memberof FilterTableComponent
   */
  closeFilter() {
      this.close.emit();
    }

  /*Modal help*/
  /*Open Modal Help**/
  openModalHelp(file: string) {
    this.dialog.open(ModalHelpComponent, { maxWidth: '600px', data: { file } });
  }

  /**
   *  Send filter to de view
   *
   * @param {Filter[]} filters
   * @memberof FilterTableComponent
   */
  sendFilterThrowSalved(filters: Filter[]) {
    this.filters = filters;
    this.calculateFormula()
    this.sendFilter()
  }
/**
 * Create a new filter
 *
 * @param {Filterfactory} filter
 * @memberof FilterTableComponent
 */
createNewFilter(filter: Filterfactory) {
    this._FiltersFactoryService.create(filter).subscribe(
      () =>  this.getAllFilter(),
      error => console.log(error)
    );
  }

/**
 * Get all filters salved
 *
 * @memberof FilterTableComponent
 */
getAllFilter() {
    const query = new QueryFetcher();
          query.filters.push({
            field : 'belongs_to',
            operator : '=',
            value : location.pathname
          });
          this._FiltersFactoryService.getAll(query)
          .pipe(map(res  => res.data.map( item => { item.storage = JSON.parse(item.storage);
            return item})) ).subscribe((data) => {

      this.filtersSalved = data;
    });
  }
  /**
   * Remove filter by id
   *
   * @param {number} id
   * @memberof FilterTableComponent
   */
  removeSalvedFilter(id: number) {
    let dialogRef = this.dialog.open(ModalDeleteAdvanceComponent, {
      disableClose: true,
      autoFocus: false,
      width: '400px'
    });
    dialogRef.afterClosed().subscribe((canDelete: boolean) => {
      if (canDelete) {
        this.loading = true;
        this._FiltersFactoryService.delete({id}).subscribe(
          () => {
            this.getAllFilter();
            this.deleteChip = false;
            this.loading = false;
            this.openSnackBar('El filtro se ha borrado correctamente', 'ACEPTAR');
          },
          error => {
            this.loading = false;
            console.log(error);
          }
        );
      }
    });
  }
/**
 * Open modal to write name filter.
 *
 * @memberof FilterTableComponent
 */
openModalInput() {
    const options = {
      data: { title: 'Añade un nombre al filtro', place: 'Guardar filtro'}
    };
    const dialogRef = this.dialog.open(ModalSimpleInputComponent, options);
          dialogRef.afterClosed().subscribe( filter_name => {
            if(filter_name) {
              const saveFilter: Filterfactory = {
                name: filter_name,
                storage: JSON.stringify(this.filters),
                belongs_to: location.pathname
              };
              this.createNewFilter(saveFilter);
            }
          });
  }
  setEditFilter(filter:  Filterfactory) {
    this.editChip = filter;
    this.filters = filter.storage;
    this.calculateFormula();
  }
  editFilter() {
    this.loading = true;
    const filterUpdated = this.filters;
    const body = {
      id : +this.editChip.id,
      name: this.editChip.name,
      storage : JSON.stringify(filterUpdated)
    };
    this._FiltersFactoryService.update(body)
      .subscribe(
        () => {
          this.loading = false;
          this.getAllFilter();
          this.editChip = false;
         this. openSnackBar('Se ha editado correctamente el filtro', 'ACEPTAR');
        }
      );
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  toggleChips(type: string) {
    if (type === 'edit') {
      this.editChip = true;
      this.deleteChip = false;
    } else if (type === 'delete') {
      this.deleteChip = true;
      this.editChip = false;
    }
    window.setTimeout(() => {
      this._ngZone.run(() => {
        this.editChip = false;
        this.deleteChip = false;
      });
    }, 10000);
  }

}
