<mat-form-field>
  <mat-hint align="start" *ngIf="(optionSearch$ | async)?.length == 0">No hay resultados, prueba buscando otra vez 😀</mat-hint>
  <mat-spinner [diameter]="20" matSuffix *ngIf="loading"></mat-spinner>
  <mat-chip-list #chipList>
    <mat-chip (removed)="removeChip(chip)" *ngFor="let chip of chipsListResults">{{ chip.name }}<mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input #inputSeach (keyup)="searchInput$.next($event.target.value)" placeholder="Buscar" #searchInput
      [matAutocomplete]="auto" [matChipInputFor]="chipList">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="setChip($event)">
    <mat-option *ngFor="let option of optionSearch$ | async" [value]="option">
      {{option.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
