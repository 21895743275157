import { HttpClient, HttpHeaders} from '@angular/common/http'
/* Injector */
import {InjectorInstance} from '../app.module';
/*MODEL*/
import { QueryFetcher } from './QueryFetcher';
/*environment*/
import { environment } from '../../environments/environment';
/*RXJS*/
import { Observable } from 'rxjs';


type ID =  string | number

export class ServiceOperation{
  http: HttpClient;
  url: string;
  options: {headers: HttpHeaders, withCredentials: boolean};

  constructor(path: string) {
    this.http = InjectorInstance.get<HttpClient>(HttpClient);
    this.url =  environment.apiUrl + '/' + path;

  }

  /***** CRUD   *******/
  /**
   * Create
   * @param body
   */
  create(body: any  ):Observable<any> {
    return this.http.post( `${this.url}`, this._removeTimestams(body) )
  }
  /**
   * Read all
   * @param query
   */
  getAll(query?: QueryFetcher): Observable<any> {
    query = query ||  new QueryFetcher()
    const size = new TextEncoder().encode(JSON.stringify(query)).length
    const kiloBytes = size / 1024;
    if(kiloBytes > 7) return this.http.post(`${this.url}/large`, query)
    else return this.http.get( `${this.url}?q=${encodeURIComponent(JSON.stringify(query))}` )
  }
  /**
   * Read detail by id
   * @param id
   * @param query
   */
  get(id:ID , query?: QueryFetcher): Observable<any> {
    return this.http.get( `${this.url}/${id}${query ? '?q='+ encodeURIComponent(JSON.stringify(query)) : ''}` )
  }
  /**
   * Update by id. "Id is mandatory into body object"
   * @param body
   */
  update(body: {id: string | number } ): Observable<any> {
    return this.http.put( `${this.url}/${body.id}`, this._removeTimestams(body))
  }

  /**
   * Delete element "Id is mandatory into object"
   * @param element
   */
  delete(element: {id: ID }): Observable<any> {
    return this.http.delete(`${this.url}/${element.id}` )
  }

  massiveUpdate(payload : {update: Object, q: QueryFetcher } | any ): Observable<any> {
    return this.http.put(`${this.url}/massive-update`, payload )
  }

  massiveDestroy(query): Observable<any> {
    return this.http.delete(`${this.url}/massive-destroy?q=${encodeURIComponent(JSON.stringify(query))}`)
  }

  batchUpdate(elements: { update: Array<Object>}): Observable<any> {
    return this.http.put(`${this.url}/batch-update`, elements)
  }
  /**
   * Get a blob data with CSV table data
   * @param query
   */
  exportData(query?: QueryFetcher) {
    return this.http.get(`${this.url}/export?q=${encodeURIComponent(JSON.stringify(query))}`, {responseType:'blob'})
  }

  uploadFiles(files: File[], json?: any){
    const formData = new FormData();
    for(let file of files) {
      formData.append('files', file, file.name )
    }

    if(json) {
      formData.append("json", JSON.stringify(json));
    }
    return this.http.post(`${this.url}`, formData )
  }

  private _removeTimestams(body) {
    if(body.created_at) delete body.created_at
    if(body.updated_at) delete body.updated_at
    return body;
  }
}
