import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import {  MainNavComponent } from '../main-nav/main-nav.component'
@Component({
  selector: 'app-debug-sql',
  templateUrl: './debug-sql.component.html',
  styleUrls: ['./debug-sql.component.scss']
})
export class DebugSqlComponent implements OnInit {
  @Input() sql: string;
  @Input() filters: any;
  constructor(
    private elem: ElementRef,
    private mainNav: MainNavComponent
  ) { }

  ngOnInit() {
    if(environment.production) this.hide()
    this.mainNav.modoDebug.subscribe( debug => {
     debug ? this.show() : this.hide();
    });
  }
  hide() {
    this.elem.nativeElement.style.display = 'none';
  }
  show() {
    this.elem.nativeElement.style.display = 'block';
  }


}
