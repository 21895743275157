import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSidenavContent } from '@angular/material/sidenav'
import { ModalWeatherComponent } from '../modal-weather/modal-weather.component';
/* Services */
import { SessionService } from '../../services/session.service';
import { WeatherService } from '../../services/weather.service';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { bounceInUpAnimation } from 'src/app/animation';
import { User } from '@/interfaces/user.interface';
import ITEMS from './items'
import {version} from "../../../../package.json";
import { LocalStorageService } from 'ngx-localstorage';

interface Item {
  label: string
  action?: string
  children?: Item[],
  view?: string,
  icon?: string,
  permission?: string
}

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  animations: [bounceInUpAnimation]
})
export class MainNavComponent {
  sql_checked: boolean;
  version: string = version
  modoDebug: ReplaySubject<boolean> = new ReplaySubject();
  user : User;
  production: boolean = environment.production;
  currentUrl: string;
  dateTime: Date = new Date();
  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  weather$: Observable<any>
  items: any = ITEMS;
  
  itemsDebug: Item[] = [
    {label: 'Hefame-product-provider', view:'hefame-product-provider', permission: 'hefame-hefame-product-providers.list', children: [
      {label: 'Resumen', action: '/main/hefame/product-provider/dashboard'},
      {label: 'Listado', action: '/main/hefame/product-provider/list'},
      {label: 'Nuevo', action: '/main/hefame/product-provider/new'}
    ]},
    {label: 'Hefame-rules', view:'hefame-rules', permission: 'hefame-hefame-rules.list', children: [
      {label: 'Resumen', action: '/main/hefame/rules/dashboard'},
      {label: 'Listado', action: '/main/hefame/rules/list'},
      {label: 'Nuevo', action: '/main/hefame/rules/new'}
    ]},
    {label: 'Hefame-rule-Definitions', view:'hefame-rule-definitions', permission: 'hefame-hefame-rule-definitions.list', children: [
      {label: 'Resumen', action: '/main/hefame/rule-definitions/dashboard'},
      {label: 'Listado', action: '/main/hefame/rule-definitions/list'},
      {label: 'Nuevo', action: '/main/hefame/rule-definitions/new'}
    ]}
  ]

  constructor(
    private _storage: LocalStorageService,
    private _dialog: MatDialog,
    private _router: Router,
    private _sessionSession : SessionService,
    private _breakpointObserver: BreakpointObserver,
    private _weatherService: WeatherService ){

      this.sql_checked = this._storage.get('sql_checked');
      this.modoDebug.next(this.sql_checked)
      
      this._router.events.subscribe(event => {
        if(event instanceof NavigationEnd) {
          this.currentUrl = location.pathname
          console.log(this.currentUrl)
        }
      })
    }

  ngOnInit() {
     this.user = this._sessionSession.localUser;
     // Call weather service
     this.weather$ = this._weatherService.getWeather();
      // Generate clock
    setInterval(() => this.dateTime = new Date(), 1000);
    console.log(this.user);
  }

  setSqlCheckedState() {
    this.sql_checked = !this.sql_checked
    this._storage.set('sql_checked',this.sql_checked)
  }

  logOut() {
    this._sessionSession.logOut()
  }

  goTo(where) {
    this._router.navigate([where]);
  }

  modalWheater() {
    this._dialog.open(ModalWeatherComponent, {width: '600px'});
  }
  get isSuperAdmin(): boolean {
    const local_roles = this._storage.get('user_roles') || []
    const roles = local_roles
    return roles.find( r => r.id == 3);
  }

}
