import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { environment } from '../../environments/environment'
import { User } from '../interfaces/user.interface';
import { Observable, BehaviorSubject } from 'rxjs';
import { Crypto } from '../classes/Crypto';
import { UsersService } from './users.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService{
  localUser : User;
  sub: BehaviorSubject<User>
  user: Observable<User>;
  crypto: Crypto = new Crypto()

  constructor(
    private _user: UsersService,
    private _router: Router,
    private storage: LocalStorageService) {
    const localUserStorageHEX = storage.get('user')
    this.localUser = localUserStorageHEX ? this.crypto.descrypt(localUserStorageHEX) : {logged: false}
    this.sub = new BehaviorSubject(this.localUser)
    this.user = this.sub.asObservable()
  }

  /**
   * Save encrypt user data.
   * @param token <string>
   */
  login(token) {

      this.localUser = {token, name: null, logged : true}
      this.storage.set('user', this.crypto.encrypt(this.localUser))
      this.sub.next(this.localUser);
  }
  /**
   * LogOut user, remove data user from client and redirect logout.
   */
  logOut() {
    const token = this.getToken;
    this.localUser = {logged: false}
    this.storage.remove('user')
    if(environment.production) window.location.href = `${environment.logoutRedirect}?token=${token}`
    else this._router.navigateByUrl('/login');
  }

  set setUserData({id, name, roles}) {
    this.localUser = {...this.localUser, id, name, roles}
    this.storage.set('user', this.crypto.encrypt(this.localUser))
  }

  get getToken(): string {
    return this.localUser.token;
  }

  get isLoggedIn(): boolean {
    return this.localUser.logged;
  }

  get userId() {
    return this.localUser.id
  }

  get userName() {
    return this.localUser.name
  }


}
