import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[animationNumber]'
})
export class AnimationNumberDirective{
  @Input() set animationNumber(value: number){
    // If number is not a number
    if(isNaN(value)) {
      this.element.nativeElement.innerHTML = 'No data'
      this.element.nativeElement.style.fontSize = '1em'
    } else {
      const repeat = 100;
      this.initial = value
      this.add = this.initial / repeat
      this.time = 1000 / repeat
      this.generateNumber(this.increment)
    }
  }
  initial: number;
  increment: number = 0
  add: number
  time: number;
  constructor(
    private element: ElementRef
  ) { }

  generateNumber(number: number) {
    this.printNumber(number)
    if(this.increment >= this.initial) return this.printNumber(this.initial)
    setTimeout(()=> {
      this.increment = this.increment + this.add
      this.generateNumber(this.increment)
    }, this.time )
  }

  printNumber(number: number) {
    number = Math.floor(number)
    this.element.nativeElement.innerHTML = number
  }

}
