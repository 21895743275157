import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { bounceInLeftAnimation } from '../../animation';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-top-main-menu',
  templateUrl: './top-main-menu.component.html',
  styleUrls: ['./top-main-menu.component.scss'],
  animations: [bounceInLeftAnimation]
})
export class TopMainMenuComponent implements OnInit {
  @Input() scroll_secuencial: boolean = null;
  @Input() title: string = "Default";
  @Input() icon: string;
  @Input() exportButtom: boolean = false;
  @Input() exportPermission: string
  @Output() onClickExportButton: EventEmitter<any> = new EventEmitter()

  back: boolean = false;
  constructor(
    public location: Location,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    // If window history is only one we can go back
    if(window.history.length >  1) this.back = true;
    // Export permission false
    if(!this.exportPermission) this.exportButtom = false
  }
  onClick(){
    this.onClickExportButton.emit()
  }

  openDownloadModal() {
    const data = {
      title: 'Descarga en progreso',
      message: 'Tu descarga se esta procesando en el apartado Descargas'
    }
      this.dialog.open(ModalAlertComponent, { maxWidth: '600px', data });
    }
}
